
.container_userJourneyModal{
    z-index: 50;
    top:  1%;
    left: 7px;
    right: 0;
    position: fixed;
    height: -webkit-fill-available;
    width: 99%;
    height: 98%;
    background-color: #fff;
    animation-duration: 0.5s;
    animation-fill-mode:both;
    animation-name: fadeInBottom;
    overflow-y: scroll !important;
    border-radius: 24px;
    border: 3px solid #dfdfdf;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(20%);
    }
    to { opacity: 1 }
}

.innerContainer_userJourneyModal{
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 78px;
    height: auto;
    margin: 60px;
    width:auto;
    overflow-y: scroll;
}

.innerContainer_applicantDetais_UJM{
    gap: 8px;
    display: flex;
    padding: 0 112px;
    flex-direction: column;
    align-items: flex-start;
}

.innerContainer_applicantDetais_nameContainer_UJM{
    display: flex;
    align-items: baseline;
    gap: 20px;
    width: 100%;
}

.innerContainer_applicantDetais_name_UJM {
    flex: 1; /* Allow the input to grow and shrink based on available space */
    width: auto;
    font: 500 36px 'Poppins';
    border: 2px solid transparent;
    overflow-wrap: break-word;
}

.innerContainer_applicantDetais_name_UJM:focus{
    padding: 0 12px ;
    border: 2px solid #0085FF;
    border-radius: 6px;
}


.innerContainer_applicantDetais_others_UJM{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.innerContainer_applicantDetais_email_UJM{
    display: flex;
    align-items: center;
    gap: 20px;
}


.innerContainer_applicantDetais_emailText_UJM{
    font: 400 14px 'Poppins';
}


.innerContainer_fields_userJourneyModal{
    display: inline-flex;
    flex-direction: column;
    gap: 58px;
}

.innerContainer_applicantDetais_phoneInput_UJM {
    flex: 1; /* Allow the input to grow and shrink based on available space */
    width: auto;
    font: 400 14px 'Poppins';
    border: 2px solid transparent;
    overflow-wrap: break-word;
}

.innerContainer_applicantDetais_phoneInput_UJM:focus{
    padding: 0 6px ;
    border: 2px solid #0085FF;
    border-radius: 6px;
}


/* ******************* */
/* Onboarding Info Section  */
/* ******************* */

.iC_folds_UJM{
    display: flex;
    padding: 42px 112px;
    flex-direction: column;
    align-items: flex-start;
    gap: 38px;
    border-radius: 24px;
    background: #F8F8F8;
}

.iC_folds_heading_button_UJM{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 52px;
}

.iC_folds_button_UJM{
    color: #353939;
    font: 400 14px Poppins;
    cursor: pointer;
}

.iC_folds_heading_UJM{
    color: #000;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.iC_folds_rounds_section_UJM{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.iC_folds_rounds_section_additional_UJM{
    margin-top:20px;
}

.iC_folds_rounds_heading_UJM{
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}



.iC_folds_section_UJM{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}

.iC_folds_section_infoBoardinf_UJM{
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}

.dropdown{
    height: auto;
    user-select: none;
    min-width: 315px;
    display: flex;
    flex-direction: column;
    gap:6px;
    position: relative;
}

.iC_fields_onboardingInfo_dropdownHeading{
    color: #353939;
    font: 400 14px Poppins;
}

.dropdown .dropdown_button{
    padding: 12px 20px; 
    justify-content: space-between;
    background: #fff;
    border: 2px solid #EDEDED;
    display: flex;
    align-items: center; 
    color: #000;
    font: 400 14px Poppins;
    border-radius: 6px;
    gap: 8px;
}

.curson_pointer{
    cursor: pointer;
}

.curson_not_selected{
    cursor: not-allowed;
}

.dropdown .dropdown_content{
    position: absolute;
    top: 88px;
    padding: 10px 12px;
    background: #fff;
    font-weight: bold;
    color: #333;
    width: 99%;
    color: #000;
    font: 400 14px Poppins;
    border-radius: 6px;
    max-height: 300px;
    overflow-y: scroll;
    z-index: 100;
    border: 2px solid #EDEDED;
}

/* ::-webkit-scrollbar{
    display: none;
} */

.dropdown .dropdown_content .dropdown_option{
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
}

.dropdown .dropdown_content .dropdown_option:hover{
    background-color: #F8F8F8;
    border-radius: 6px;
}

/* *************************** */
/* TEXTAREA - Start */
/* *************************** */

.textArea_container_UserJourneyModal{
    display: flex;
    flex-direction: column;
    gap:6px;
    width: 47%;
}

.textArea_label_UserJourneyModal{
    color: #353939;
    font: 400 14px Poppins;
}

.textArea_input_UserJourneyModal {
    width: 100%;
    max-height: 300px;
    padding: 12px;
    color: #353939;
    font: 400 14px Poppins;
    resize: none;
    border-radius: 6px;
    border: 2px solid #EDEDED;
}

.textArea_input_fold1_UserJourneyModal{
    width: 30% !important;
}

.textArea_input_fold6_UserJourneyModal{
    width: 100% !important;
}

/* *************************** */
/* TEXTAREA - End */
/* *************************** */


.iC_fields_onboardingInfo_schoolsContainerfold1_UJM{
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}

.iC_fields_onboardingInfo_schoolsdeals_UJM{
    display: flex;
    flex-direction: column;
    gap: 6px
}

.iC_fields_onboardingInfo_schoolsdeals_heading_UJM{
    color: #353939;
    font: 400 14px Poppins;
}

.iC_fields_onboardingInfo_schoolsdeals_inner_UJM{
    display:flex;
    gap:4px;
}

.iC_fields_onboardingInfo_schoolsdeals_valueContainer_UJM{
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: #FFF;
}

.iC_fields_onboardingInfo_schoolsdeals_value_UJM{
    color: #353939;
    font: 400 18px Poppins;
}

.iC_fields_onboardingInfo_schoolsdeals_substract_UJM{
    display: flex;
    padding: 16px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: linear-gradient(131deg, #0085FF -1.83%, #000AFF 101.22%);
    cursor: pointer;
}

.iC_fields_onboardingInfo_schoolsdeals_addition_UJM{
    display: flex;
    padding: 16px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: linear-gradient(131deg, #0085FF -1.83%, #000AFF 101.22%);
    cursor: pointer;
}












/* ******************* */
/* Bottom Fixed Close and Submit Button Section  */
/* ******************* */

.bottomButtonsSection_userJourneyModal{
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 72px;
    border-top: 1px solid #EDEDED ;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 60px;
    gap: 20px;
    background-color: #fff;
}



.submit_bottomButtonsSection_userJourneyModal{
    gap: 18px;
    display: flex;
    padding: 12px 20px;
    align-items: center;
    border-radius: 8px;
    background: linear-gradient(91deg, #0085FF 0.12%, #000AFF 100%);
    -webkit-transition: gap 0.5s;
    transition: gap 0.5s;
    border: none;
}

.submit_bottomButtonsSection_userJourneyModal:hover{
    border: none;
    gap:28px;
}

.submitText_bottomButtonsSection_userJourneyModal{
    color: #fff;
    font: 500 16px 'Poppins'
}

.submitIcon_bottomButtonsSection_userJourneyModal{
    font-size: 17px;
    color: #fff;
}

.close_bottomButtonsSection_userJourneyModal{
    font: 400 16px 'Poppins';
    padding: 12px 20px;
    color: #353939;
    border: 2px solid #dfdfdf;
    border-radius: 8px;
}













@media only screen and (min-width : 768px) and (max-width : 1425px) {
    .textArea_input_fold1_UserJourneyModal{
        width: 100% !important;
    }
}



@media only screen and (min-width : 768px) and (max-width : 1024px) {
    .innerContainer_userJourneyModal{
        gap: 50px;
        margin: 32px;
    }

    .innerContainer_applicantDetais_UJM {
        padding: 0 60px;
    }

    .innerContainer_fields_userJourneyModal {
        gap: 32px;
    }

    .iC_folds_UJM {
        padding: 42px 60px;
        gap: 32px;
    }

    .dropdown {
        min-width: 280px;
    }
    
}

@media only screen and (min-width : 469px) and (max-width : 767px) {

    .container_userJourneyModal {
        left: 3px;
        width: 98%;
    }

    .iC_folds_heading_UJM {
        font-size: 22px;
    }

    .innerContainer_applicantDetais_name_UJM {
        font: 500 26px 'Poppins';
    }

    .innerContainer_userJourneyModal{
        gap: 50px;
        margin:20px;
    }

    .innerContainer_applicantDetais_UJM {
        padding: 0 20px;
    }

    .innerContainer_fields_userJourneyModal {
        gap: 32px;
    }

    .iC_folds_UJM {
        padding: 32px 20px;
        gap: 32px;
    }

    .iC_folds_section_UJM {
        gap: 32px;
    }

    .iC_fields_onboardingInfo_schoolsContainerfold1_UJM {
        gap: 32px;
    }

    .dropdown {
        min-width: 100%;
    }

    .iC_folds_heading_button_UJM {
        gap: 16px;
    }

    .bottomButtonsSection_userJourneyModal{
        justify-content: space-between;
        padding: 0 20px;
    }

    .textArea_container_UserJourneyModal{
        width: 100%;
    }

    .textArea_input_fold1_UserJourneyModal{
        width: 100% !important;
    }
    
    .textArea_input_fold6_UserJourneyModal{
        width: 100% !important;
    }

    .close_bottomButtonsSection_userJourneyModal{
    color: #353939;
    border: none;
    background-color: #F8F8F8;
    border-radius: 8px;
    }
    
}


@media only screen and (min-width : 200px) and (max-width : 468px) {

    .container_userJourneyModal {
        left: 3px;
        width: 98%;
    }

    .iC_folds_heading_UJM {
        font-size: 22px;
    }

    .innerContainer_applicantDetais_name_UJM {
        font: 500 26px 'Poppins';
    }

    .innerContainer_userJourneyModal{
        gap: 50px;
        margin:20px;
    }

    .innerContainer_applicantDetais_UJM {
        padding: 0 20px;
    }

    .innerContainer_fields_userJourneyModal {
        gap: 32px;
    }

    .iC_folds_UJM {
        padding: 32px 20px;
        gap: 32px;
    }

    .iC_folds_section_UJM {
        gap: 32px;
    }

    .iC_fields_onboardingInfo_schoolsContainerfold1_UJM {
        gap: 32px;
    }

    .dropdown {
        min-width: 100%;
    }

    .iC_folds_heading_button_UJM {
        gap: 16px;
    }

    .bottomButtonsSection_userJourneyModal{
        justify-content: space-between;
        padding: 0 20px;
    }

    .textArea_container_UserJourneyModal{
        width: 100%;
    }

    .textArea_input_fold1_UserJourneyModal{
        width: 100% !important;
    }
    
    .textArea_input_fold6_UserJourneyModal{
        width: 100% !important;
    }

    .close_bottomButtonsSection_userJourneyModal{
    color: #353939;
    border: none;
    background-color: #F8F8F8;
    border-radius: 8px;
    }
    
}
