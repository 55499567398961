.interviewPrepQuestionContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap:26px;
}

.interviewPrepQuestion{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap:20px;
    background-color: #F8F8F8;
    padding: 12px 16px;
    border-radius: 12px;
}

.interviewPrepQuestionName{
    font: 500 16px 'satoshi';
}

.videoUploadCancelButtons{
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}

.videoUploadButton{
    border-radius: 8px;
    background-color: #51cdc2;
    color: #fff;
    font: 400 14px 'Poppins';
    padding: 12px 20px;
}

.videoCancelButton{
    font: 400 14px 'Poppins';
    padding: 12px 20px;
    color: #353939;
    background-color: #dfdfdf ;
    border-radius: 8px;
}