.phase3_MinimizedChatGptModal{
    position: fixed;
    bottom: 30px;
    right: 50px;
    cursor: pointer;
    z-index: 51;
    border-radius: 100px;
    padding: 10px;
    --borderWidth: 3px;
    border-radius: var(--borderWidth);
}

.phase3_MinimizedChatGptModal:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(100 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}
  
  
@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.phase3_MinimizedChatGptModal_Icon{
    width: 30px;
    height: 30px;
}

/* *********************************** */
/* MinimizedChatGptModal */
/* *********************************** */

.MinimizedChatGptModal{
    position: fixed;
    bottom: 100px;
    right: 50px;
    z-index: 51;
    border: 3px solid #dfdfdf;
    border-radius: 16px;
    max-height: 76%;
    min-height: 60%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    gap: 20px;
    width: 350px;
}

.MinimizedChatGptModal_topContainer{
    display: flex;
    justify-content: space-between;
    align-items:center;
    gap: 10px;
}

.MinimizedChatGptModal_expandCompress{
    width: auto;
    height: 18px;
    cursor: pointer;
}

@media (max-width: 768px) {

    .phase3_MinimizedChatGptModal{
        right: 20px;
    }

   .MinimizedChatGptModal{
    width: 90% !important;
    right: 20px;
   } 

   .MinimizedChatGptModal_expandCompress{
    display: none
   }
}

.MinimizedChatGptModal_heading{
    font: 500 14px "Poppins";
    background-color: #f8f8f8;
    padding: 12px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: fit-content;
}

.MinimizedChatGptModal_heading .phase3_MinimizedChatGptModal_Icon{
    width: 20px;
    height: 20px;
}

.MinimizedChatGptModal_contentSection{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    overflow-y: auto;
}

.MinimizedChatGptModal_contentSection::-webkit-scrollbar{
    display: none;
}

.MinimizedChatGptModal_sendMessageTextArea{
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    border: 2px solid #dfdfdf;
    border-radius: 6px;
    padding: 12px;
    position: sticky;
    bottom: 0;
    font: 500 13px "Poppins";
}

.ChatgptMessage_container{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.ChatgptMessage_container_chatmate{
    align-items: start;
}

.ChatgptMessage_container_user{
    align-items:end;
}
  
.ChatgptMessage_assistants{
    font: 500 12px "Poppins";
}

.ChatgptMessage_textarea_chatmate{
    width: auto;
    min-width: 85%;
    background-color: #F5F9FC;
    padding: 10px;
    font: 500 13px "Poppins";
    border-radius: 8px;
}

.ChatgptMessage_textarea_assistant{
    width: auto;
    min-width: 85%;
    background-color: #acecff;
    padding: 10px;
    font: 500 13px "Poppins";
    border-radius: 8px;
}

.MinimizedChatGptModal_textarea_buttons_section{
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
    justify-content: flex-end;
}

.MinimizedChatGptModal_sendButton{
    display: flex;
    padding: 8px 14px;
    align-items: center;
    border-radius: 100px;
    background: linear-gradient(91deg, #0085FF 0.12%, #000AFF 100%);
    border: none;
    color: #fff;
    gap: 8px;
    font: 400 15px "Poppins";
}

.MinimizedChatGptModal_clearButton{
    display: flex;
    padding: 8px 14px;
    align-items: center;
    border-radius: 100px;
    background-color: #f8f8f8;
    border: none;
    color: #000;
    gap: 8px;
    font: 400 15px "Poppins";
}

.MinimizedChatGptModal_temperatureheading{
    font: 500 14px "Poppins";
}
